import {
  createRouter,
  createWebHistory,
  type RouteRecordRaw,
} from 'vue-router';
import RouterView from '@/views/RouterView.vue';
import { useAuthUserStore } from '@/store/use-auth-user-store';
import { useErrorStore } from '@/store/use-error-store';
import { firebaseAuth } from '@/config/firebase';
import { useCurrentOrganizationStore } from '@/store/use-current-organization-store';
import { getFirstPathParam } from '@/utils/common';
import { apiGetOrganizationsList } from '@/api/organizations';
import { storeToRefs } from 'pinia';
import { trackPageChange } from '@/utils/hub-spot';

const routes: RouteRecordRaw[] = [
  {
    path: '/auth/login',
    name: 'login',
    component: () => import('../views/auth/login/Login.vue'),
    meta: {
      authForbidden: true,
    },
  },
  {
    path: '/auth/reset-password',
    name: 'reset-password',
    component: () => import('../views/auth/reset-password/ResetPassword.vue'),
    meta: {
      authForbidden: true,
    },
  },
  {
    path: '/auth/set-new-password',
    name: 'set-new-password',
    component: () =>
      import('../views/auth/set-new-password/SetNewPassword.vue'),
    meta: {
      authForbidden: true,
    },
  },
  {
    path: '/auth/register',
    name: 'register',
    component: () => import('../views/auth/register/Register.vue'),
    meta: {
      authForbidden: true,
    },
  },
  {
    path: '/auth/logout',
    name: 'logout',
    component: () => import('../views/auth/logout/Logout.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '',
    component: () => import('../layouts/main-layout/main-layout.vue'),
    async beforeEnter(to) {
      if (to.path === '/') {
        const { nodes: organizations } = await apiGetOrganizationsList({
          perPage: 1,
        });

        return `/${organizations[0].slug}`;
      }
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/:organizationSlug',
        component: RouterView,
        children: [
          {
            path: '',
            name: 'root',
            component: RouterView,
          },
          {
            path: 'key-metrics',
            name: 'keyMetrics',
            component: () => import('../views/key-metrics/KeyMetrics.vue'),
          },
          {
            path: 'campaigns',
            name: 'campaignsOverview',
            component: () =>
              import(
                '../views/campaigns/campaign-overview/campaign-overview.vue'
              ),
          },
          {
            path: 'campaigns/create',
            name: 'createCampaign',
            component: () =>
              import('../views/campaigns/create-campaign/create-campaign.vue'),
          },
          {
            path: 'campaigns/:campaignId/details',
            name: 'campaignDetails',
            component: () =>
              import(
                '../views/campaigns/campaign-details/campaign-details.vue'
              ),
            props: true,
          },
          {
            path: 'campaigns/:campaignId/edit',
            name: 'editCampaign',
            component: () =>
              import('../views/campaigns/edit-campaign/edit-campaign.vue'),
            props: true,
          },
          {
            path: 'content',
            name: 'contentOverview',
            component: () =>
              import('../views/content/content-overview/ContentOverview.vue'),
          },
          {
            path: 'content/create/resource',
            name: 'createResourceContent',
            component: () =>
              import('../views/content/create-content/CreateContent.vue'),
          },
          {
            path: 'content/create/socials',
            name: 'createSocialsContent',
            component: () =>
              import('../views/content/create-content/CreateContent.vue'),
          },
          {
            path: 'content/create/files',
            name: 'createFilesContent',
            component: () =>
              import('../views/content/create-content/CreateContent.vue'),
          },
          {
            path: 'content/:contentId/details',
            name: 'contentDetails',
            props: true,
            component: () =>
              import('../views/content/content-details/ContentDetails.vue'),
          },
          {
            path: 'content/:contentId/edit',
            name: 'editContent',
            props: true,
            component: () =>
              import('../views/content/edit-content/EditContent.vue'),
          },
          {
            path: 'hubs',
            name: 'hubsOverview',
            component: () =>
              import(
                /* webpackChunkName: "hubsOverview" */ '../views/hubs/hubs-overview/HubsOverview.vue'
              ),
          },
          {
            path: 'hubs/create',
            name: 'createHub',
            component: () =>
              import(
                /* webpackChunkName: "createHub" */ '../views/hubs/create-hub/CreateHub.vue'
              ),
          },
          {
            path: 'hubs/:hubId/edit',
            props: true,
            name: 'editHub',
            component: () =>
              import(
                /* webpackChunkName: "editHub" */ '../views/hubs/edit-hub/EditHub.vue'
              ),
          },
          {
            path: 'settings',
            name: 'settings',
            component: () =>
              import(
                /* webpackChunkName: "hubsOverview" */ '../views/settings/Settings.vue'
              ),
          },
          {
            path: 'people',
            name: 'peopleOverview',
            component: () =>
              import('../views/people/people-overview/PeopleOverview.vue'),
          },
          {
            path: 'people/add',
            name: 'addPerson',
            component: () => import('../views/people/add-person/AddPerson.vue'),
          },
          {
            path: 'people/upload/people',
            name: 'uploadPeople',
            component: () =>
              import('../views/people/upload/people/UploadPeople.vue'),
          },
          {
            path: 'people/upload/dependents',
            name: 'uploadDependents',
            component: () =>
              import('../views/people/upload/dependents/UploadDependents.vue'),
          },
          {
            path: String.raw`people/:personId(\d+)/details`,
            name: 'personDetails',
            props: true,
            component: () =>
              import('../views/people/person-details/PersonDetails.vue'),
          },
          {
            path: String.raw`people/:personId(\d+)/edit`,
            name: 'editPerson',
            props: true,
            component: () =>
              import('../views/people/edit-person/EditPerson.vue'),
          },
          {
            path: 'people/pending',
            name: 'pendingUsers',
            component: () => import('../views/people/pending/PendingUsers.vue'),
          },
          {
            path: 'profile',
            name: 'profile',
            component: () => import('../views/profile/Profile.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/super-admin',
    name: 'superAdmin',
    component: () =>
      import('../layouts/super-admin-layout/super-admin-layout.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'organizations',
        name: 'organizations',
        component: () =>
          import('../views/super-admin/organizations/organizations.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import('../views/NotFound.vue'),
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.onError((err) => {
  const { handleError } = useErrorStore();

  handleError(router, err);
});

router.beforeEach(async (to, from, next) => {
  try {
    if (from.matched.length > 0) {
      // track page changes after initial page load
      trackPageChange(to.fullPath);
    }

    const authUserStore = useAuthUserStore();

    const { loadCurrentOrganization } = useCurrentOrganizationStore();

    const organizationSlug = getFirstPathParam(to.params.organizationSlug);

    await authUserStore.initializeAuthUser();

    if (
      organizationSlug &&
      authUserStore.authUser !== null &&
      !authUserStore.authUser.isSuperAdmin
    ) {
      await loadCurrentOrganization(organizationSlug);
    }

    const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
    const authForbidden = to.matched.some((x) => x.meta.authForbidden);

    const isLoggedIn = authUserStore.authUser !== null;

    if (requiresAuth && !isLoggedIn) {
      next({
        path: '/auth/login',
        query: to.name === 'logout' ? undefined : { redirect: to.fullPath },
      });
    } else if (authForbidden && isLoggedIn) {
      next('/');
    } else if (
      to.path !== '/super-admin/organizations' &&
      to.path !== '/auth/logout' &&
      authUserStore.authUser?.isSuperAdmin
    ) {
      next('/super-admin/organizations');
    } else {
      next();
    }
  } catch (err) {
    next(new Error('Vue-Router error', { cause: err }));
  }
});

router.beforeEach((to) => {
  if (to.name === 'root') {
    const currentOrganizationStore = useCurrentOrganizationStore();
    const { currentOrganization } = storeToRefs(currentOrganizationStore);

    if (currentOrganization.value?.displayQuestionnaire) {
      return `/${currentOrganization.value.slug}/key-metrics`;
    }
    return `/${currentOrganization.value.slug}/content`;
  }
});
export default router;
